<template>
  <div>
    <div class="w1400">
      <div class="clearfix leimulist">
        <div class="clearfix">
          <span>类目：</span>
          <span v-for="(leimuarr, index) in leimuarrs"
                @click="changes(index)"
                v-bind:class="{ active: index == current }"
          >{{leimuarr.name}}</span>
        </div>
        <div class="clearfix">
          <span>地区：</span>
          <span v-for="(leimuarr, index) in addresslist"
                @click="changes(index)"
                v-bind:class="{ active: index == current }"
          >{{leimuarr.name}}</span>
        </div>
        <div class="clearfix">
          <span>距离：</span>
          <span v-for="(distance, index) in distancelist"
                @click="changes(index)"
                v-bind:class="{ active: index == current }"
          >{{distance.name}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import NavHead from '@/components/content/mainTabbar/NavHead'
  import LogoBar from '@/components/content/mainTabbar/LogoBar'
export default {
  name: 'Marketprice',
  data() {
    var self = this // 存储当前vue实例this,这行是重
    return {
      leimuarrs:[
        {name:"基本金属"},
        {name:"废钢"},
        {name:"废铜"},
        {name:"废铝"},
        {name:"废不锈钢"},
        {name:"废纸"},
        {name:"废铅"},
        {name:"塑料"},
        {name:"钢材"},
        {name:"基本金属"},
        {name:"废钢"},
        {name:"废铜"},
        {name:"废铝"},
        {name:"废不锈钢"},
        {name:"废纸"},
        {name:"废铅"},
        {name:"塑料"},
        {name:"钢材"},
        {name:"基本金属"},
        {name:"废钢"},
        {name:"废铜"},
        {name:"废铝"},
        {name:"废不锈钢"},
        {name:"废纸"},
        {name:"废铅"},
        {name:"塑料"},
        {name:"钢材"},
        {name:"基本金属"},
        {name:"废钢"},
        {name:"废铜"},
        {name:"废铝"},
        {name:"废不锈钢"},
        {name:"废纸"},
        {name:"废铅"},
        {name:"塑料"},
        {name:"钢材"}
      ],
      addresslist:[
        {name:"上海"},
        {name:"北京"},
        {name:"南京"},
        {name:"苏州"},
        {name:"废不锈钢"},
        {name:"废纸"},
        {name:"废铅"},
        {name:"塑料"},
        {name:"钢材"},
        {name:"基本金属"},
        {name:"废钢"},
        {name:"废铜"},
        {name:"废铝"},
        {name:"废不锈钢"},
        {name:"废纸"},
        {name:"废铅"},
        {name:"塑料"},
        {name:"钢材"},
        {name:"基本金属"},
        {name:"废钢"},
        {name:"废铜"},
        {name:"废铝"},
        {name:"废不锈钢"},
        {name:"废纸"},
        {name:"废铅"},
        {name:"塑料"},
        {name:"钢材"},
        {name:"基本金属"},
        {name:"废钢"},
        {name:"废铜"},
        {name:"废铝"},
        {name:"废不锈钢"},
        {name:"废纸"},
        {name:"废铅"},
        {name:"塑料"},
        {name:"钢材"}
      ],
      distancelist:[
        {name:"50公里内"},
        {name:"100公里内"},
        {name:"150公里内"},
      ],
      current:0,//行情价格默认第一个
      page:1,//默认第一页
      count:0,//总页数
    }
  },
  components: {
    NavHead,
    LogoBar
  },
  methods: {
    changes(index){
      this.current = index;
      console.log(index)
    },
    tjbtnclick(){
      this.page=1;
      this.current=0;
    },
    newsbtnclick(){
      this.page=1;
      this.current=1;
    },
    changenext(page){
      console.log(page)
    },
  }
}
</script>

<style scoped>
.bannerpng{
  width: 100%;
  height:180px;
  background: url("~assets/img/1920.png");
  background-color:#3D60BB;
  text-align: center;
  line-height: 180px;
  color: #fff;
  font-size: 40px;
  font-family: "Microsoft Himalaya";
}
  .leimulist{
    border: 1px solid #E6EBF5;
    margin-top: 50px;
    padding: 0 14px;
  }
.leimulist span{
  line-height: 42px;
  font-size: 14px;
  cursor: pointer;
  margin: 0 14px;
  float: left;
}
.leimulist span.active{
  color: #25449A;
}
.fengleilist{
  margin-bottom: 60px;
}
  .flistleft{
    width: 800px;
    float: left;
  }
  .flistright{
    width: 350px;
    float: right;
  }
.flistright .righttop{
background: #25449A;
  border-radius: 5px;
  margin-top: 38px;
  overflow: hidden;
  }
.flistright .righttop p{
  text-align: center;
  line-height: 84px;
  font-size: 18px;
  color: #fff;
}
.flistright .righttop span{
  display: block;
  font-size: 18px;
  color: #303133;
  line-height: 60px;
  text-align: center;
  background: #D9EBFF;
  margin-top: 30px;
}
.flistright .rightbottom{
  margin-top: 23px;
}
.flistright .rightbottom p{
  line-height: 40px;
  background: #E6EBF5;
  border-left: 3px solid #25449A;
  font-size: 15px;
  padding-left: 18px;
}
.tiaojiabody{
  height: 412px;
  border: 1px solid #E6EBF5;
  padding: 12px 8px;
  font-size: 14px;
  overflow: hidden;
}
.tiaojiabody li{
  line-height: 38px;
  cursor: pointer;
}
.tiaojiabody li:hover{
  color: #25449A;
}
.tiaojiabody li span.fl:before {
  content: "";
  width: 4px;
  height: 4px;
  display: inline-block;
  border-radius: 50%;
  background: #7C7C7C;
  vertical-align: middle;
  margin-right: 15px;
}
.tablebottom .timechose{
  border-bottom: 1px solid #E6EBF5;
  cursor: pointer;
  margin-top: 22px;
}
.tablebottom .timechose .infortitle{
  border-bottom: 1px solid #E6EBF5;
}
.tablebottom .timechose ul.infortitle li{
  width: 66px;
  float: left;
  font-size: 16px;
  text-align: center;
  line-height: 46px;
}
.tablebottom .timechose ul.infortitle li:nth-child(1){
  margin-right: 20px;
}
.tablebottom .timechose ul.infortitle li.active{
  position: relative;
  top: 1px;
  color: #25449A;
  border-bottom: 2px solid #25449A;
}
.inforall{
  margin-top: 25px;
}
  .inforall .inforlist{
    border: 1px solid #E6EBF5;
    padding:10px 20px 15px;
    font-size: 16px;
    margin-bottom: 25px;
  }
.inforall .inforlist .listtitle{
  margin: 10px 0;
}
.inforall .inforlist .listtitle span:nth-child(1){
  float: left;
  font-size: 12px;
  color: #25449A;
  background: #DCEDFF;
  line-height: 24px;
  padding: 0 5px;
  border-radius: 2px;
}
.inforall .inforlist .listtitle span:nth-child(2){
  color: #25449A;
  font-weight: bold;
  margin-left: 12px;
  float: left;
}
.inforall .inforlist .listtitle span:nth-child(3){
  float: right;
  font-size: 14px;
}
.inforall .inforlist  .infornews li{
  line-height: 38px;
}
.inforall .inforlist  .infornews li span:nth-child(1){
 float: left;
}
.inforall .inforlist  .infornews li span:nth-child(2){
  float: right;
}
</style>
<style>
  /*.ivu-table-column-center .tubiao{*/
    /*cursor:pointer;*/
    /*display:block;*/
    /*width:22px;*/
    /*height:20px;*/
    /*background: url('~assets/img/tbpng.png');*/
    /*margin: 0 auto;*/
  /*}*/
  .flistleft .ivu-table th{
    background-color: #E6EBF5;
  }
  .flistleft .ivu-table-stripe .ivu-table-body tr:nth-child(2n) td, .flistleft  .ivu-table-stripe .ivu-table-fixed-body tr:nth-child(2n) td{
    background-color:#F4F6FB
  }
</style>
